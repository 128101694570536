import { SchoolService } from "@/services/school.service";
import { mapGetters } from "vuex";
export const planningMixin = {
    data() {
        return { plannerArrayFiltered: [] };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapGetters({
            plannerArray: "planningList",
            settings: "settings",
        })
    },
    methods: {
        findByKey(arr, id) {
            return arr.find(function (item) {
                return item.key === id;
            });
        },
        fetchData() {
            SchoolService.getAll("planning")
                .then(response => this.buildPlannerArray(response.data))
                .catch(err => {
                    console.log("Events " + err);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        },
        buildPlannerArray(data) {
            var tm;
            this.$store.commit("REFRESH_PLANNING");
            for (tm of this.terms) {
                for (let yr = 0; yr < this.years.length; yr++) {
                    let tempUnits = data
                        .filter(pd => {
                            return (
                                pd.term.toUpperCase().includes(tm.key.toUpperCase()) &&
                                pd.course.year_id == this.years[yr].id
                            );
                        })
                        .map(term => {
                            return term.course;
                            // return {
                            //   name: term.course.name,
                            //   id: term.course.id,
                            //   styles: term.course.styles,
                            //   type_id: term.course.type_id,
                            // };
                        });
                    let planObj = this.createPlannerObj(tm, yr, tempUnits);
                    this.$store.commit("ADD_PLANNING", planObj);
                }
                if (this.mixinName === "planning") {
                    this.plannerArrayFiltered = this.groupPlannerArray();
                } else {
                    this.plannerArrayFiltered = this.plannerArray;
                }
            }
        },
        createPlannerObj(termObj, index, units) {
            this.plannerArrayCount += 1;
            return {
                id: this.plannerArrayCount,
                year: this.years[index].name,
                year_id: this.years[index].id,
                term: termObj.name,
                term_key: termObj.key,
                units: units ? units : {},
                colour: this.colours[index]
            };
        },
    }
};

