<template>
  <div class="bg-gray-200 pt-10">
    <planningAdd
      @close="showPlanningEdit = false"
      @refresh="refreshGrid"
      v-show="showPlanningEdit"
      :editMode="editMode"
      :term="selectedTerm"
      :year="selectedYear"
    ></planningAdd>

    <section class="planning-grid h-screen">
      <div
        class="
          h-24
          w-auto
          bg-white
          py-6
          ml-6
          mr-6
          pl-6
          flex flex-row
          items-center
          rounded
        "
      >
        <div class="relative w-full md:w-11/12 text-center top-0 lg:mt-0 z-888">
          <dropdown
            v-if="years.length > 0"
            :options="yearDropdown"
            :css="'text-white'"
            :selected="filteredYear"
            v-on:updateOption="yearSelect"
            class="w-2/3 lg:w-1/2 bg-gray-900 pl-3 mb-4 inline-block"
          ></dropdown>
        </div>
      </div>
      <div class="bg-white mt-6 ml-6 max-w-xl rounded pb-6 pt-2">
        <div class="flex flex-row mx-6 text-gray-500">
          <div class="flex-1 pl-4">
           
              unit

          </div>
          <div class="w-32 pl-10">
            <button
              v-on:click="sort('year')"
              :class="sortBy === 'year' ? 'text-gray-700' : 'text-gray-500'"
            >
              year
              <i class="fas" :class="iconDirection('year')"></i>
            </button>
          </div>
          <div class="w-32 pl-6">
            <button
              v-on:click="sort('term')"
              :class="sortBy === 'term' ? 'text-gray-700' : 'text-gray-500'"
            >
              term
              <i class="fas" :class="iconDirection('term')"></i>
            </button>
          </div>
        </div>
        <div v-for="(plan, index) in plannerArrayFiltered" :key="index">
          <div v-for="(unit, index) in plan.units" :key="index">
            <div
              class="my-2 mx-6 p-2 rounded-md text-xs cursor-pointer"
              v-bind:style="getBackgroundColor(plan.colour)"
              @click="unitSelect(unit)"
            >
              <div class="flex flex-row">
                <div class="flex-1 pl-2">
                  {{ unit.name }}
                </div>
                <div class="w-24 mx-2">{{ plan.year }}</div>
                <div class="w-24 mx-2">{{ plan.term }}</div>
              </div>
            </div>

            <!-- <hr
            v-if="hrDecision(plan)"
            class="mx-6 my-3 bg-gray-200"
            style="height:2px; border:none;"
          /> -->
          </div>

          <div
            v-if="plan.units.length < 2 && sortBy != 'units'"
            class="my-2 mx-6 p-2 rounded-md text-xs"
            v-bind:style="getBackgroundColor(plan.colour)"
          >
            <div class="flex flex-row">
              <div class="flex-1 pl-2">
                <button
                  class="h-6 w-6 rounded-full grid justify-center"
                  style="padding-top: 2px"
                  @click="selectCell(plan)"
                >
                  <i class="fas fa-plus-circle text-xl opacity-50"></i>
                </button>
              </div>
              <div class="w-24 mx-2">{{ plan.year }}</div>
              <div class="w-24 mx-2">{{ plan.term }}</div>
            </div>
          </div>

          <hr
            v-if="index + 1 < plannerArrayFiltered.length"
            class="mx-6 my-3 bg-gray-200"
            style="height: 2px; border: none"
          />
        </div>
        <div v-if="!plannerArrayFiltered.length" class="h-screen">
          <div class="my-2 mx-6 p-2 rounded-md text-xs">
            <div class="flex flex-row">
              <div class="flex-1 pl-2">No results found</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { planningMixin } from "@/mixins/planningMixin.js";
import { yearGroupMixin } from "@/mixins/yearGroupMixin.js";

import { mapGetters } from "vuex";
import {
  yearList,
  termList,
  colours,
  calendarYearList,
} from "@/assets/js/utils";
const planningAdd = () => import("@/components/planning/ThePlanningAdd.vue");
const dropdown = () => import("@/components/Dropdown.vue");

const defaultSort = "term";
export default {
  mixins: [planningMixin, yearGroupMixin],
  data() {
    return {
      searchModel: "",
      searchInit: true,
      includeScore: true,
      sortBy: "term",
      sortDirection: "asc",
      editMode: false,
      terms: termList(),
      colours: colours(),
      selectedTerm: {},
      selectedYear: 0,
      filteredYear: {},
      showPlanningEdit: false,
      plannerArrayCount: 0,
      selectedCalendar: {},
      tempArray: [],
    };
  },
  components: {
    planningAdd,
    dropdown,
  },
  watch: {
    sortBy: function () {
      this.sortedPlans();
    },
    sortDirection: function () {
      this.sortedPlans();
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      //  plannerArray: "planningList",
    }),
    options() {
      return {
        keys: ["term", "year", "units.name"],
        includeScore: this.includeScore,
        threshold: 0.1,
      };
    },
    yearDropdown() {
      let results = [{ id: 0, name: "Select a year group" }].concat(this.years);
      if (this.filteredYear.id === undefined) {
        this.filteredYear = results[0];
      }
      return results;
    },
  },
  methods: {
    iconDirection(sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === "desc") {
          return "fa-sort-up";
        } else {
          return "fa-sort-down";
        }
      }
      return "fa-sort";
    },
    sort(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    sortedPlans: function () {
      let origArray = [];
      let vm = this
      origArray = vm.filterYear(this.plannerArray)

      console.log(JSON.stringify(origArray))

        vm.plannerArrayFiltered = this.$orderBy(
          origArray,
          [vm.sortBy],
          [vm.sortDirection]
        )
    },
    setFocus() {
      this.$refs.fuse.children[0].focus();
    },
    clearSearch() {
      this.$refs.fuse.children[0].value = "";
      this.searchInit = false;
      this.plannerArrayFiltered = this.plannerArray;
    },
    selectCell(plan) {
      this.showPlanningEdit = true;
      this.selectedYear = parseInt(plan.year_id);
      this.selectedTerm = this.findByKey(this.terms, plan.term_key);
      // this.selectedTerm = { term: plan.term, key: plan.term_key };
    },
    refreshGrid(data) {
      let payload = {
        term: this.selectedTerm.key,
        year: this.selectedYear,
        unit: data,
      };
      this.$store.commit("ADD_PLANNING_UNIT", payload);
      //this.plannerArrayFiltered = this.plannerArray;
      this.showPlanningEdit = false;
    },
    getBackgroundColor(plan) {
      return { backgroundColor: "rgba(" + plan.colour + ",1)" };
    },
    yearSelect(year) {
      this.filteredYear = year;
      this.sortedPlans();
    },
    filterYear(arr) {
      var year = this.filteredYear.id;
      var results = arr.filter(function (p) {
        if (year > 0) {
          return p.year_id === year;
        }

        return true;
      });

      return results
    },
    unitSelect(unit) {
      this.$store.commit("setUnit", unit);
      this.$router.push({
        name: "UnitView",
        params: { routeName: "planning-list" },
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
input:focus {
  outline: none;
  border: 2px solid rgba(59, 130, 246, 1);
}
</style>